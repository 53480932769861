import { LicenseInfo } from '@mui/x-license-pro'
import 'react-toastify/dist/ReactToastify.css'
import 'react-phone-input-2/lib/material.css'

import { CacheProvider, EmotionCache } from '@emotion/react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import {
  MUI_DATA_GRID_LICENSE_KEY,
  STRIPE_PUBLISHABLE_KEY,
} from 'constants/env'
import { UserProvider } from 'context'
import { NextPageWithLayout } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { NextAdapter } from 'next-query-params'
import * as React from 'react'
import { ReactNode, useEffect } from 'react'
import { Bounce, ToastContainer, Flip } from 'react-toastify'
import { createTheme } from 'theme'
import { createEmotionCache } from 'theme/createEmotionCache'
import { QueryParamProvider } from 'use-query-params'
import { QueryParamAdapter } from 'use-query-params/src/types'
import 'simplebar-react/dist/simplebar.min.css'

function Adapter(props: {
  children: (adapter: QueryParamAdapter) => React.ReactElement | null
}) {
  return <NextAdapter {...props} />
}

const clientSideEmotionCache = createEmotionCache()
const theme = createTheme()

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY)
LicenseInfo.setLicenseKey(MUI_DATA_GRID_LICENSE_KEY)

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache
  Component: NextPageWithLayout
}

function MyApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: MyAppProps) {
  const getLayout = Component.getLayout || ((page: ReactNode) => page)

  useEffect(() => {
    // Workaround to fix FOUC(https://en.wikipedia.org/wiki/Flash_of_unstyled_content)
    document.documentElement.style.visibility = 'visible'
  }, [])

  return (
    <>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta name='charset' content='utf-8' />
          <meta
            name='viewport'
            content='width=device-width, initial-scale=1, viewport-fit=cover, maximum-scale=1, user-scalable=0'
          />
          <title key='title'>
            Insight AI: AI Data Analyst, 24/7 insights on your business data
          </title>
          <meta name='robots' content='noindex, nofollow'></meta>
        </Head>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <UserProvider>
            <QueryParamProvider
              adapter={Adapter}
              options={{ updateType: 'replaceIn' }}
            >
              <Elements
                stripe={stripePromise}
                options={{
                  locale: 'en',
                }}
              >
                {getLayout(<Component {...pageProps} />)}
              </Elements>
            </QueryParamProvider>
          </UserProvider>
          <ToastContainer
            position='bottom-right'
            autoClose={2000}
            theme='light'
            icon={true}
            closeButton={true}
            newestOnTop
            closeOnClick
            hideProgressBar
            pauseOnFocusLoss
            pauseOnHover
            transition={Bounce}
          />
        </ThemeProvider>
      </CacheProvider>
    </>
  )
}

export default MyApp
