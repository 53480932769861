import Head from 'next/head'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Box,
  Button,
  Card,
  Container,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
// import { useAuth } from 'src/hooks/use-auth'
import { Layout as AuthLayout } from 'layouts/Auth/layout'
import { useUserContext } from 'hooks'
import { login } from 'api/requests/auth'
import { TokenStorage } from 'utils/auth'
import { getCurrentUserInfo } from 'api/requests/user'
import { NextPageWithLayout } from 'next'
import { Logo } from 'components/Logo'
import useAuthentication from 'hooks/useAuth'

const Login: NextPageWithLayout = () => {
  useAuthentication()
  const { setUser, user } = useUserContext()

  // check if there is
  const router = useRouter()
  const redirectToUrl = router.query.redirect as string

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      submit: null,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Must be a valid email')
        .max(255)
        .required('Email is required'),
      password: Yup.string().max(255).required('Password is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        const response = await login(values)
        if (response.data) {
          const { accessToken, refreshToken } = response.data
          const tokenStorage = TokenStorage.getInstance()
          tokenStorage.setAccessToken(accessToken)
          tokenStorage.setRefreshToken(refreshToken)

          try {
            const result = await getCurrentUserInfo()
            const user = result.data
            if (!user) {
              return
            }
            setUser(user)
            router.push('/workflows')
            // await router.push(redirectToUrl ? redirectToUrl : '/apps')
          } catch (error) {
            console.error(error)
          }
        }
      } catch (err: any) {
        helpers.setStatus({ success: false })
        // helpers.setErrors({ submit: err.response?.data?.error })
        helpers.setSubmitting(false)
      }
    },
  })

  return (
    <>
      <Head>
        <title>Login - Insight AI</title>
        <meta
          name='description'
          content='Login to access your account on Insight AI'
        ></meta>
        <meta name='robots' content='noindex, nofollow'></meta>
      </Head>

      <Box
        component='main'
        sx={{
          flex: '1 1 auto',
          flexDirection: 'column',
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ mb: 2 }}>
          <Logo></Logo>
        </Box>
        <Container maxWidth='xs'>
          <Card>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                p: 4,
              }}
            >
              <Stack spacing={1} sx={{ mb: 3 }}>
                <Typography variant='h5' sx={{ textAlign: 'center' }}>
                  Welcome back!
                </Typography>
                <Typography color='text.secondary' variant='body2'>
                  Don&apos;t have an account? &nbsp;
                  <NextLink href={'/register'}>Register</NextLink>
                </Typography>
              </Stack>
              <form noValidate onSubmit={formik.handleSubmit}>
                <Stack spacing={3}>
                  <TextField
                    error={!!(formik.touched.email && formik.errors.email)}
                    fullWidth
                    helperText={formik.touched.email && formik.errors.email}
                    label='Email Address'
                    name='email'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type='email'
                    value={formik.values.email}
                  />
                  <TextField
                    error={
                      !!(formik.touched.password && formik.errors.password)
                    }
                    fullWidth
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    label='Password'
                    name='password'
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    type='password'
                    value={formik.values.password}
                  />{' '}
                  <FormHelperText sx={{ mt: 0, fontSize: '11px' }}>
                    Forgot your password? Contact us: support@getinsightai.com
                  </FormHelperText>
                </Stack>
                {formik.errors.submit && (
                  <Typography color='error' sx={{ mt: 3 }} variant='body2'>
                    {formik.errors.submit}
                  </Typography>
                )}
                <Button
                  fullWidth
                  size='large'
                  sx={{ mt: 3 }}
                  disabled={formik.isSubmitting}
                  type='submit'
                  variant='contained'
                >
                  Continue
                </Button>
              </form>
            </Box>
          </Card>
          <Box
            sx={{
              mb: 3,
              textAlign: 'center',
            }}
          >
            <div></div>
          </Box>
        </Container>
      </Box>
    </>
  )
}

Login.getLayout = page => <AuthLayout>{page}</AuthLayout>

export default Login
