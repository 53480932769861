import { axiosExtended } from 'api/axios'

import { GetDataTypesResponse } from './getDataTypes.types'
import { getGetDataTypesUrl } from '../urls'

export const getDataTypes = async () => {
  const { href } = getGetDataTypesUrl()
  const response = await axiosExtended.get<GetDataTypesResponse>(href, {
    withAuth: true,
  })
  return response.data
}
