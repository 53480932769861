import { API_URL } from 'constants/env'

export const getGetDataTypesUrl = (): URL => {
  const url = `${API_URL}/data-type/get-data-types`
  return new URL(url)
}

export const getCreateDataTypeUrl = (): URL => {
  const url = `${API_URL}/data-type/create-data-type`
  return new URL(url)
}

export const getDeleteDataTypeUrl = (id: number): URL => {
  const url = `${API_URL}/data-type/delete-data-type/${id}`
  return new URL(url)
}
